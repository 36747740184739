import image1 from "../sliderImages/Image_slider1.webp";
import image2 from "../sliderImages/Image_slider2.webp";
import image3 from "../sliderImages/Image_slider3.webp";
import image4 from "../sliderImages/Image_slider4.webp";
import image5 from "../sliderImages/Image_slider5.webp";
import image6 from "../sliderImages/Image_slider6.webp";
import image7 from "../sliderImages/Image_slider7.webp";

const sliderImages = [image1, image2, image3, image4, image5, image6, image7];

export default sliderImages;
